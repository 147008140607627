<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - Banners</h5>
      <div class="btns">
        <b-form-input type="search" v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button variant="info" @click="add">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered hover :items="banners" :fields="columns" :filter="filter">
      <template slot="active" slot-scope="row">
        {{ parseInt(row.value) === 1 ? $t('yes') : $t('not') }}
      </template>
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button variant="success" class="mx-2" @click="edit(row.item)">
            <i class="fa fa-pencil"></i>
          </b-button>
          <b-button variant="danger" class="mx-2" @click="openModalDestroy(row.item)">
            <i class="fa fa-trash"></i>
          </b-button>
        </div>
      </template>
    </b-table>
    <modal-edit-create-banner :banner="banner" @addBanner="addBanner" @editBanner="editBanner" />
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ banner.name}}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ModalEditCreateBanner from './ModalAddEdit'
import { mapGetters } from 'vuex'

export default {
  components: {
    ModalEditCreateBanner
  },
  data () {
    return {
      filter: '',
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'link', label: 'Link', sortable: true },
        { key: 'order', label: this.$t('position'), sortable: true },
        { key: 'active', label: this.$t('show'), sortable: true },
        { key: 'created_at', label: this.$t('createdAgo'), sortable: true },
        { key: 'actions', label: '' }
      ],
      banners: [],
      banner: {}
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'tournament',
      'user',
      'console'
    ])
  },
  mounted() {
    const userSaved = localStorage.getItem('user')
    if (userSaved) {
      const user = JSON.parse(userSaved)
      if (user.type !== 'SUPER') {
        this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
        return
      }
    }
    this.changeTitleBar(this.tournament.name + ' - Banners')
    this.fetchData()
  },
  methods: {
    openModalDestroy (banner) {
      this.banner = banner
      this.$refs.modalDelete.show()
    },
    addBanner (banner) {
      this.banners.unshift(banner)
    },
    add () {
      this.banner = {}
      this.$root.$emit('bv::show::modal', 'modalAddEdit')
    },
    edit (banner) {
      this.banner = JSON.parse(JSON.stringify(banner))
      this.$root.$emit('bv::show::modal', 'modalAddEdit')
    },
    editBanner (banner) {
      this.banners = this.banners.map(ban => {
        if (ban.id === banner.id) {
          ban.name = banner.name
          ban.order = banner.order
          ban.link = banner.link
          ban.active = parseInt(banner.active)
          ban.image = banner.image
        }
        return ban
      })
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const path = `auth/${this.lang}/${this.console}/tournaments/${tournamentId}/banners`
      this.$axios.get(path).then((response) => {
        this.banners = response.data.data
      })
    },
    destroy () {
      const tournamentId = this.$route.params.tournamentId
      const path = `auth/${this.lang}/${this.console}/tournaments/${tournamentId}/banners/${this.banner.id}/delete`
      this.$axios.delete(path).then(() => {
        this.banners = this.banners.filter(ban => {
          return ban.id !== this.banner.id
        })
        this.$refs.modalDelete.hide()
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    }
  }
}
</script>
